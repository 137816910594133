'use strict';

function removeOtherCookies (pCookieToLive) {
    if ($.isArray(pCookieToLive) === false) {
        pCookieToLive = [];
    }

    var pairs = document.cookie.split(";");
    for (var i = 0; i < pairs.length; i++) {
        var pair = pairs[i].split("=");
        var cookieKey = $.trim(pair[0]);

        if ((typeof cookieKey === typeof "") 
            && (cookieKey.length > 0) && ($.inArray(cookieKey, pCookieToLive) === -1)) {
            $.cookie(cookieKey, null); // remove it
        }
    }
}

function doLogin (pUsername, pPassword, pSuccess, pFailure) {
    removeOtherCookies(['user_id', 'user_hash', 'expiration_date', 'scope']);
    api_async.auth.login(pUsername, pPassword, function (pData) {
        if (pData.user_id === undefined) {
            failedLogin($.i18n._('nixps-cloudflow-login.error-login_failed'));
        } else {
            removeOtherCookies(['user_id', 'user_hash', 'expiration_date', 'scope']);
            // Store our login in a cookie
            $.cookie('user_id', pData.user_id, { expires: 2, path: '/' });
            $.cookie('user_hash', pData.user_hash, { expires: 2, path: '/' });
            $.cookie('expiration_date', pData.expiration_date, { expires: 2, path: '/' });
            $.cookie('scope', null, {path: '/'});
            pSuccess(pData.user_id);
        }
    }, function (pError) {
        if (pError.error_code !== undefined) {
            pFailure($.i18n._('nixps-cloudflow-login.error-' + pError.error_code));
        } else if (pError.error != undefined) {
            pFailure(pError.error);
        }
    });
}

function getVersionText (pVersionData) {
    var version = pVersionData.major + "." + pVersionData.minor;

    if (pVersionData.rev !== 0) {
        version += "." + pVersionData.rev;
    }

    var versionText = $.i18n._('nixps-cloudflow-login.version', [version, pVersionData.build]);
    return versionText;
}

function setFrameSession (userId) {
    return $.Deferred(function (pDefer) {
        api_defer.frame.os.get().then(function () {
            api_defer.auth.get_current_user().then(function (currentUser) {
                return api_defer.auth.create_session(currentUser.username);
            }).then(function (sessionKey) {
                var session = sessionKey.session;
                return api_defer.frame.os.set_cloudflow_session(session, {force: true});
            }).then(function () {
                pDefer.resolve();
            }).fail(function () {
                pDefer.resolve();
            });
        }).then(function () {
            pDefer.resolve();
        }).fail(function () {
            pDefer.resolve();
        });
    });
}

module.exports = {
    doLogin: doLogin,
    getVersionText: getVersionText,
    setFrameSession: setFrameSession
}