'use strict';

var loginFunctions = require('../../nebulaportal/js/login/loginUtil.js');
var doLogin = loginFunctions.doLogin;
var getVersionText = loginFunctions.getVersionText;
var setFrameSession = loginFunctions.setFrameSession;

/**
 * The login dialog
 * Triggers following events
 *     'login', username, userid
 */
var login = {

	doLogin: doLogin,

	setupUI: function() {
		 if (sOrigURL === "<!--ORIG-->") {
			sOrigURL = window.location.href;
		}

		if (sOrigUser === "<!--USERNAME-->") {
			sOrigUser = "";
		}

		if (sOrigUser !== "") {
			$('#login_dialog #username').val(sOrigUser);
		}

		$('div#google').hide();

		api_async.auth.generate_oauth2_url('google', function (pData) {
			if ((pData.result !== undefined) && (pData.result !== null)) {
				$('a#google-button').attr('href', pData.result);
				$('div#google').show();
			}
		}, function () {
			/* ignore error */
		});

		$("#login_dialog").on('submit', function (pEvent) {
			pEvent.preventDefault();
		});

		// BIND LOGIN
		$('.login_action').click(login.login);
		$('.login_action').css("cursor", "pointer");

		if ($('#login_dialog #username').val() === '') {
			$("#login_dialog #username").focus();
		} else {
			$("#login_dialog #password").focus();
		}

		$(document).keypress(function (pEvent) {
			if (pEvent.keyCode == 13) {
				login.login();
			}
		});

		api_async.portal.version(login.setVersion);

		if (window.location.toString().indexOf('error=1') >= 0) {
			login.loginFailed($.i18n._('nixps-cloudflow-login.error-user_not_registered'));
		}
	},

    setup_ui: function() { // to handle old html pages caches
        login.setupUI();
    },

	/**
	 * Called on a login action in the dialog
	 */
	login: function () {
		$('.login_action').prop('disabled', true);
		var username = $("#login_dialog #username").val();
		var password = $("#login_dialog #password").val();
		login.doLogin(username, password, login.loginSuccessful, login.loginFailed);
	},

	/**
	 * Sets the version in the title of the dialog
	 */
	setVersion: function (pData) {
		var loginText = getVersionText(pData);
		$("#loginversion").text('(' + loginText + ')');
	},

	/**
	 * Callback on successful login
	 */
	loginSuccessful: function (pUserId) {
		setFrameSession(pUserId).then(function () {
			document.location.assign(sOrigURL);
		});
	},

	/**
	 * Callback on a failed login
	 */
	loginFailed: function (pReason) {
		$("#loginstatus").text(pReason);
		$('.login_action').prop('disabled', false);
	},

	/**
	 * Shows the login dialog
	 */
	show: function() {
		$("#login_dialog #username").focus();
	}
}

window.login = login;