require('./nixps-portal-api.js');
require('./nixps-login.js');

function setVersionTooltip (pVersion) {
    // control if major and minor number are present, otherwise do nothing.
    if (typeof pVersion.major === "number" && pVersion.major >= 0 && typeof pVersion.minor === "number" && pVersion.minor >= 0) {
        var versionToolTip = $.i18n._('nixps-cloudflow-login.version', [pVersion.major + '.' + pVersion.minor, pVersion.build]);
        if (typeof pVersion.rev === "number" && pVersion.rev > 0) {
            versionToolTip += (" " + $.i18n._('nixps-cloudflow-login.versionupdate', [pVersion.rev]));
        }
        $('.cf-login-buildNumber').attr('title', versionToolTip);
    }
}

function renderLogin (pLanguage) {
    document.title = $.i18n._(sTitle);
    $('input[type=submit]').attr('value', $.i18n._('nixps-cloudflow-login.ok'));
    $('body').show();
    login.setupUI();
}

$(document).ready(function () {
    var prefsPromise = api_defer.preferences.get_for_current_user('', 'language')
    var versionPromise = api_defer.portal.version()

    $.when(prefsPromise, versionPromise).then(function (prefs, version) {
        var language = prefs.preferences;
        var build = version.build;
        return $.when($.get('/cloudflow_' + language + '.json?' + build), language);
    }).then(function (translations, language) {
        $.i18n.setDictionary(translations[0]);
        $('body').find("span.translate").each(function(index, element) {
            e = $(element);
            e._t(e.attr('key'));
        });
        renderLogin(language);
        // Set the version number in the toolbar
        versionPromise.then(setVersionTooltip);
    }).fail(function () {
        $.get('/cloudflow_en.json?' + (new Date().getTime())).then(function(p_translations) {
            $.i18n.setDictionary(p_translations);
            $('body').find("span.translate").each(function(index, element) {
                e = $(element);
                e._t(e.attr('key'));
            });
            renderLogin('en');
            // Set the version number in the toolbar
            versionPromise.then(setVersionTooltip);
        }).fail(function (pError) {
            console.error(pError);
        });
    });
});
